
import styles from '../styles/body.module.scss'
import btnStyles from '../styles/buttons.module.scss'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import CallMissedOutgoingIcon from '@mui/icons-material/CallMissedOutgoing';
import { useNavigate } from 'react-router-dom';

export default function ReportType({setReportType, setReportSender}) {

  const navigate = useNavigate();

  const nextPage = (reportType) =>{
    console.log("1: "+reportType);
    setReportType(reportType);
    setReportSender('operative');
    if(reportType === "Accident/Injury"){
      navigate('/login')
      //navigate("/site")
    }else{
      navigate("/observation_type")
    }

  }


  return (
    <div>
      <Box className={styles.questionH1Top} >
        <h1>Select the type of report</h1>
        </Box>
        <Box className={btnStyles.buttonContainer} >
            <Button startIcon={<RemoveRedEyeIcon />} className={btnStyles.btn} size="large" variant="contained"
             onClick={()=> nextPage("Observation")} 
             >Observation</Button>
            <Button startIcon={<CallMissedOutgoingIcon />} className={btnStyles.btn} size="large" variant="contained"
             onClick={()=> nextPage("Near Miss")} 
             >Near Miss</Button>
            <Button startIcon={<MedicalServicesIcon />} className={btnStyles.btn} size="large" variant="contained" 
            onClick={()=> nextPage("Accident/Injury")} 
            >Accident or Injury</Button>

      </Box>
      <div className={styles.bottomDiv}>
          <p className={styles.bottomParagraph}>
            Were you looking for Magrock Induction? <a class="privacyLink" href="https://magrockinduction.co.uk/" target="_blank" >Click Here</a>.
          </p>
      </div>
        
    </div>
  );
}

