import { useState} from 'react';
import styles from '../styles/body.module.scss'
import {Box, TextField} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useNavigate } from 'react-router-dom';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Button from '@mui/material/Button';
import btnStyles from '../styles/buttons.module.scss'
import FormHelperText from '@mui/material/FormHelperText';
import{BottomNavigation, BottomNavigationAction} from '@mui/material'

export default function AccidentSummary({accidentSummary, setAccidentSummary, accidentActionTaken, setAccidentActionTaken}){
    const navigate = useNavigate();
    const goBack = () => navigate(-1);

    const [accidentSummaryError, setAccidentSummaryError] = useState(false);
    const [accidentSummaryText, setAccidentSummaryText] = useState('');

    const [accidentActionTakenError, setAccidentActionTakenError] = useState(false);
    const [accidentActionTakenText, setAccidentActionTakenText] = useState('');

    
    const handleAccidentSummaryChange = (newValue) => {
        setAccidentSummary(newValue);
    };

    const handleAccidentActionTakenChange = (newValue) => {
        setAccidentActionTaken(newValue);

    }
    

    const nextPage = () => {
        if(accidentSummary !== "" && accidentActionTaken !== ""){
            setAccidentSummaryText('');
            setAccidentSummaryError(false);
            setAccidentActionTakenText('');
            setAccidentActionTakenError(false);
            if(accidentSummary.length >250){
                setAccidentSummaryText('Accident Summary must be less than 250 characters');
                setAccidentSummaryError(true);
            }
            else  if(accidentActionTaken.length >250){
                setAccidentActionTakenText('Actions taken/recommended must be less than 250 characters');
                setAccidentActionTakenError(true);
            }
            else{
                    navigate("/riddor");
                
            }

        }
        else{
            setAccidentSummaryText('');
            setAccidentSummaryError(false);
            setAccidentActionTakenText('');
            setAccidentActionTakenError(false);
            if(accidentSummary === ""){
                setAccidentSummaryText('Please provide a summary of the accident or injury');
                setAccidentSummaryError(true);
            }
            if(accidentActionTaken === ""){
                setAccidentActionTakenText('Please provide a summary of the action taken/recommended');
                setAccidentActionTakenError(true);
            }
            
        }

    }

  return (
      <>
          <Box className={styles.questionH1Top} >
              <h1>Summary of the accident or injury</h1>
              </Box>
              <Box className={styles.textField}>
              <FormControl fullWidth margin="normal" className={styles.field} error={accidentSummaryError}>
                 <TextField error={accidentSummaryError} value={accidentSummary} inputProps={{maxLength: 250}} helperText={`${accidentSummary.length}/${250}`} fullWidth id="outlined-basic" multiline rows={5} label="Accident Summary" variant="outlined" onChange={e => handleAccidentSummaryChange(e.target.value)}  />
                 <FormHelperText>{accidentSummaryText}</FormHelperText>
              </FormControl>
              </Box>

              <Box className={styles.questionH1} >
              <h1>Action Taken/Recommended</h1>
              </Box>
              <Box className={styles.textField}>
              <FormControl fullWidth margin="normal" className={styles.field} error={accidentActionTakenError}>
                 <TextField error={accidentActionTakenError} value={accidentActionTaken} inputProps={{maxLength: 250}} helperText={`${accidentActionTaken.length}/${250}`} fullWidth id="outlined-basic" multiline rows={5} label="Action Taken/Recommended" variant="outlined" onChange={e => handleAccidentActionTakenChange(e.target.value)}  />
                 <FormHelperText>{accidentActionTakenText}</FormHelperText>
              </FormControl>
              </Box>
              
              <Box className={btnStyles.navbuttons} >
              <Button startIcon={<ArrowBackIosNewIcon />} className={btnStyles.backBtn} size="large" variant="contained" onClick={() => goBack()}>Back</Button>
              <Button endIcon={<ArrowForwardIosIcon />} className={btnStyles.nextBtn} size="large" variant="contained"
                  onClick={() => nextPage()}
              >Next</Button>

          </Box>
          <Box className={btnStyles.bottomNavContainer}>
              <BottomNavigation sx={{
                  width: '100%',
                  position: 'fixed',
                  bottom: 0,
                  height:'70px',
                  zIndex: '1000'
              }} showLabels>
                  <BottomNavigationAction id="navBackButton" label='Back' icon={<ArrowBackIosNewIcon />} onClick={() => goBack()} />
                  <BottomNavigationAction id="navNextButton" label='Next' icon={<ArrowForwardIosIcon />} onClick={() => nextPage()} />
              </BottomNavigation>
          </Box>

      </>
      
  )
}
