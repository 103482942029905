
import styles from '../styles/body.module.scss'
import btnStyles from '../styles/buttons.module.scss'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import YardIcon from '@mui/icons-material/Yard';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import{BottomNavigation, BottomNavigationAction} from '@mui/material'
import { useNavigate} from 'react-router-dom';

export default function ObservationType({setObservationType}) {
    const navigate = useNavigate();
    const goBack = () => navigate(-1);

    const nextPage = (obsType) =>{
      console.log("1: "+obsType);
      setObservationType(obsType);
  
      if(obsType === "Environmental"){
        navigate("/fuel_spillage")
      }else{
        navigate("/site")
      }
  
    }


  return (
    <div>
      <Box className={styles.questionH1Top } >
        <h1>Select the category</h1>
        </Box>
        <Box className={btnStyles.buttonContainer} >
            <Button startIcon={<CheckCircleIcon />}className={btnStyles.btn} size="large" variant="contained"
            onClick={()=> nextPage("Quality")} 
            >Quality</Button>
            <Button startIcon={<YardIcon />}className={btnStyles.btn} size="large" variant="contained"
            onClick={()=> nextPage("Environmental")} 
            >Environmental</Button>
            <Button startIcon={<HealthAndSafetyIcon />}className={btnStyles.btn} size="large" variant="contained"
            onClick={()=> nextPage("Health & Safety")}
            >Health & Safety</Button>
        </Box>
        <Box className={btnStyles.singleButtonContainer}>
            <Button startIcon={<ArrowBackIosNewIcon />}className={btnStyles.backBtn} size="large" variant="contained" onClick={()=> goBack()}>Back</Button>
      </Box>
      <Box className={btnStyles.bottomNavContainer}>
            <BottomNavigation sx={{width:'100%',
             position:'fixed', 
             bottom:0,
             height:'70px',
             zIndex: '1000'
             }} showLabels>
              <BottomNavigationAction  id="navBackButton" label='Back' icon={<ArrowBackIosNewIcon/>} onClick={()=> goBack()}/>
            </BottomNavigation>
      </Box>


    </div>
  );
}

