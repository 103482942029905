import { useState} from 'react';
import styles from '../styles/body.module.scss'
import {Box, TextField} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import{BottomNavigation, BottomNavigationAction} from '@mui/material'
import { useNavigate } from 'react-router-dom';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Button from '@mui/material/Button';
import btnStyles from '../styles/buttons.module.scss'
import FormHelperText from '@mui/material/FormHelperText';

export default function EnvDetails({weather, setWeather, lighting, setLighting, underfoot_condition, setUnderfoot_condition}){
    const navigate = useNavigate();
    const goBack = () => navigate(-1);


    const [weatherError, setWeatherError] = useState(false);
    const [weatherText, setWeatherText] = useState('');

    const [lightingError, setLightingError] = useState(false);
    const [lightingText, setLightingText] = useState('');

    const [underfoot_conditionError, setUnderfoot_conditionError] = useState(false);
    const [underfoot_conditionText, setUnderfoot_conditionText] = useState('');

    const handleWeatherChange = (newValue) => {
        setWeather(newValue);

    };

    const handleLightingChange = (newValue) => {
        setLighting(newValue);
    };

    const handleUnderfootChange = (newValue) => {
        setUnderfoot_condition(newValue);
    };

    const nextPage = () => {
        if(weather !== "" && lighting !== "" && underfoot_condition !== ""){
            setWeatherText('');
            setWeatherError(false);
            setLightingText('');
            setLightingError(false);
            setUnderfoot_conditionText('');
            setUnderfoot_conditionError(false);
            navigate("/who_witnessed");
        }
        else{
            setWeatherText('');
            setWeatherError(false);
            setLightingText('');
            setLightingError(false);
            setUnderfoot_conditionText('');
            setUnderfoot_conditionError(false);
            if(weather === ""){
                setWeatherText('Please describe the weather conditions');
                setWeatherError(true);
            }
            if(lighting === ""){
                setLightingText('Please describe the lighting conditions');
                setLightingError(true);
            }
            if(underfoot_condition === ""){
                setUnderfoot_conditionText('Please describe the conditions underfoot');
                setUnderfoot_conditionError(true);
            }
            
        }

    }

  return (
      <>
              <Box className={styles.questionH1Top}>
              <h1>What were the weather conditions?</h1>
              </Box>
              <Box className={styles.textField}>
              <FormControl fullWidth margin="normal" className={styles.field} error={weatherError}>
                 <TextField error={weatherError} value={weather} inputProps={{maxLength: 250}} helperText={`${weather.length}/${250}`} fullWidth id="outlined-basic" multiline rows={5} label="Weather Condition" variant="outlined" onChange={e => handleWeatherChange(e.target.value)}  />
                 <FormHelperText>{weatherText}</FormHelperText>
              </FormControl>
              </Box>
              <Box className={styles.questionH1}>
              <h1>What were the lighting conditions?</h1>
              </Box>
              <Box className={styles.textField}>
              <FormControl fullWidth margin="normal" className={styles.field} error={lightingError}>
                 <TextField error={lightingError} value={lighting} inputProps={{maxLength: 250}} helperText={`${lighting.length}/${250}`} fullWidth id="outlined-basic" multiline rows={5} label="Lighting Condition" variant="outlined" onChange={e => handleLightingChange(e.target.value)}  />
                 <FormHelperText>{lightingText}</FormHelperText>
              </FormControl>
              </Box>
              <Box className={styles.questionH1}>
              <h1>What were the conditions underfoot?</h1>
              </Box>
              <Box className={styles.textField}>
              <FormControl fullWidth margin="normal" className={styles.field} error={underfoot_conditionError}>
                 <TextField error={underfoot_conditionError} value={underfoot_condition} inputProps={{maxLength: 250}} helperText={`${underfoot_condition.length}/${250}`} fullWidth id="outlined-basic" multiline rows={5} label="Underfoot Condition" variant="outlined" onChange={e => handleUnderfootChange(e.target.value)}  />
                 <FormHelperText>{underfoot_conditionText}</FormHelperText>
              </FormControl>

              </Box>
              <Box className={btnStyles.navbuttons} >
              <Button startIcon={<ArrowBackIosNewIcon />} className={btnStyles.backBtn} size="large" variant="contained" onClick={() => goBack()}>Back</Button>
              <Button endIcon={<ArrowForwardIosIcon />} className={btnStyles.nextBtn} size="large" variant="contained"
                  onClick={() => nextPage()}
              >Next</Button>

          </Box>
          <Box className={btnStyles.bottomNavContainer}>
              <BottomNavigation sx={{
                  width: '100%',
                  position: 'fixed',
                  bottom: 0,
                  height:'70px',
                  zIndex: '1000'
              }} showLabels>
                  <BottomNavigationAction id="navBackButton" label='Back' icon={<ArrowBackIosNewIcon />} onClick={() => goBack()} />
                  <BottomNavigationAction id="navNextButton" label='Next' icon={<ArrowForwardIosIcon />} onClick={() => nextPage()} />
              </BottomNavigation>
          </Box>

      </>
      
  )
}
