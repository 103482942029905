import { useState} from 'react';
import styles from '../styles/body.module.scss'
import {Box, TextField} from '@mui/material';
import FormControl from '@mui/material/FormControl';

import{BottomNavigation, BottomNavigationAction} from '@mui/material'
import { useNavigate } from 'react-router-dom';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Button from '@mui/material/Button';
import btnStyles from '../styles/buttons.module.scss'
import FormHelperText from '@mui/material/FormHelperText';

export default function InjuryDetails({age, setAge, body_part_injured, setBody_part_injured, severity_of_injury, setSeverity_of_injury}){
    const navigate = useNavigate();
    const goBack = () => navigate(-1);

    const [ageError, setAgeError] = useState(false);
    const [ageText, setAgeText] = useState('');

    const [bpiError, setBpiError] = useState(false);
    const [bpiText, setBpiText] = useState('');

    const [soiError, setSoiError] = useState(false);
    const [soiText, setSoiText] = useState('');

    const handleAgeChange = (newValue) => {
        setAge(newValue);
    };

    const handleBodyPartInjuredChange = (newValue) => {
        setBody_part_injured(newValue);
    };

    const handleSeverityOfInjuryChange = (newValue) => {
        setSeverity_of_injury(newValue);
    };

    const nextPage = () => {
        if(age !== "" && body_part_injured!=="" && severity_of_injury !== ""){
            setAgeText('');
            setAgeError(false);
            setBpiText('');
            setBpiError(false);
            setSoiText('');
            setSoiError(false);

            if(age.length > 3){
                setAgeText('Please provide an age');
                setAgeError(true);
            }
            else{
                navigate("/environment_details")
            }
        }
        else{
            setAgeText('');
            setAgeError(false);
            setBpiText('');
            setBpiError(false);
            setSoiText('');
            setSoiError(false);

            if(age === ""){
                setAgeText('Please provide an age');
                setAgeError(true);
            }
            if(body_part_injured === ""){
                setBpiText('Please provide the name of a body part');
                setBpiError(true);
            }
            if(severity_of_injury === ""){
                setSoiText('Please explain the severity');
                setSoiError(true);
            }
            
        }

    }

  return (
      <>
          <Box className={styles.questionH1Top} >
              <h1>How old was the person involved?</h1>
              </Box>
              <Box className={styles.textField}>
              <FormControl fullWidth margin="normal" className={styles.field} error={ageError}>
                 <TextField error={ageError} value={age} min={0} type="number"  fullWidth id="outlined-basic" label="Age" variant="outlined" onChange={e => handleAgeChange(e.target.value= Math.max(0, parseInt(e.target.value) ).toString().slice(0,2))}  />
                 <FormHelperText>{ageText}</FormHelperText>
              </FormControl>
              </Box>
              <Box className={styles.questionH1}>
              <h1>Which body part was injured?</h1>
              </Box>
              <Box className={styles.textField}>
              <FormControl fullWidth margin="normal" className={styles.field} error={bpiError}>
                 <TextField error={bpiError} value={body_part_injured}  fullWidth id="outlined-basic" label="Body part injured" variant="outlined" onChange={e => handleBodyPartInjuredChange(e.target.value)}  />
                 <FormHelperText>{bpiText}</FormHelperText>
              </FormControl>
              </Box>
              <Box className={styles.questionH1}>
              <h1>How severe is the injury?</h1>
              </Box>
              <Box className={styles.textField}>
              <FormControl fullWidth margin="normal" className={styles.field} error={soiError}>
                 <TextField  error={soiError} value={severity_of_injury}  fullWidth id="outlined-basic" label="Severity of injury" variant="outlined" onChange={e => handleSeverityOfInjuryChange(e.target.value)}  />
                 <FormHelperText>{soiText}</FormHelperText>
              </FormControl>

              </Box>
              <Box className={btnStyles.navbuttons} >
              <Button startIcon={<ArrowBackIosNewIcon />} className={btnStyles.backBtn} size="large" variant="contained" onClick={() => goBack()}>Back</Button>
              <Button endIcon={<ArrowForwardIosIcon />} className={btnStyles.nextBtn} size="large" variant="contained"
                  onClick={() => nextPage()}
              >Next</Button>

          </Box>
          <Box className={btnStyles.bottomNavContainer}>
              <BottomNavigation sx={{
                  width: '100%',
                  position: 'fixed',
                  bottom: 0,
                  height:'70px',
                  zIndex: '1000'
              }} showLabels>
                  <BottomNavigationAction id="navBackButton" label='Back' icon={<ArrowBackIosNewIcon />} onClick={() => goBack()} />
                  <BottomNavigationAction id="navNextButton" label='Next' icon={<ArrowForwardIosIcon />} onClick={() => nextPage()} />
              </BottomNavigation>
          </Box>
      </>
      
  )
}
