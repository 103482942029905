import { useState} from 'react';
import styles from '../styles/body.module.scss'
import FSstyles from '../styles/fuelSpillage.module.scss'
import btnStyles from '../styles/buttons.module.scss'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate } from 'react-router-dom';
import{BottomNavigation, BottomNavigationAction} from '@mui/material'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';



export default function FuelSpillage({fuelSpillValue, setFuelSpillValue, measureControlValue, setMeasureControlValue}) {
    const navigate = useNavigate();
    const goBack = () => navigate(-1);

    const [error, setError] = useState(false);
    const [fuelHelperText, setFuelHelperText] = useState('');
    const [measureHelperText, setMeasureHelperText] = useState('');
    const fuelChange = (e) =>{
        setFuelSpillValue(e.target.value);
    }
    const controlChange = (e) =>{
        setMeasureControlValue(e.target.value);
    }


    const nextPage = () => {
        if(fuelSpillValue !== null && measureControlValue !== null){
            navigate("/site")

                setFuelHelperText('');
                setMeasureHelperText('');
                setError(false);

        }
        else{
            if(fuelSpillValue === null && measureControlValue === null){
                setFuelHelperText('Please select an option');
                setMeasureHelperText('Please select an option');
                setError(true);
            }else if(fuelSpillValue === null){
                setFuelHelperText('Please select an option');
                setMeasureHelperText('');
                setError(true);
            }
            else if(measureControlValue === null){
                setFuelHelperText('');
                setMeasureHelperText('Please select an option');
                setError(true);
            }
            
        }

    }

  return (
    <div>
      <Box className={styles.questionH1Top} >
        <h1>Did a fuel spillage occur?</h1>
        </Box>
        <Box className={styles.radioGroup}>
        <FormControl sx={{ m: 3 }} error={error} variant="standard">
      <RadioGroup
        row
        aria-labelledby="demo-error-radios"
        name="row-radio-buttons-group"
        value={fuelSpillValue}
        onChange={fuelChange}
      >
        <FormControlLabel sx={{'& .MuiFormControlLabel-label': {fontSize: '1.3rem'}}}  value={true} control={<Radio sx={{'& .MuiSvgIcon-root': {fontSize: 30}}} />} label="Yes" />
        <FormControlLabel sx={{'& .MuiFormControlLabel-label': {fontSize: '1.3rem'}}}value={false} control={<Radio sx={{'& .MuiSvgIcon-root': {fontSize: 30}}}/>} label="No" />

      </RadioGroup>
      <FormHelperText>{fuelHelperText}</FormHelperText>
    </FormControl>
    <Box className={styles.questionH1Top} >
        <h1>Were the correct control measures in place?</h1>
        </Box>
        <Box className={styles.radioGroup}></Box>
        <FormControl sx={{ m: 3 }} error={error} variant="standard">
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={measureControlValue}
        onChange={controlChange}
      >
        <FormControlLabel sx={{'& .MuiFormControlLabel-label': {fontSize: '1.3rem'}}}value={true} control={<Radio sx={{'& .MuiSvgIcon-root': {fontSize: 30}}}/>} label="Yes" />
        <FormControlLabel sx={{'& .MuiFormControlLabel-label': {fontSize: '1.3rem'}}}value={false} control={<Radio sx={{'& .MuiSvgIcon-root': {fontSize: 30}}}/>} label="No" />
      </RadioGroup>
      <FormHelperText>{measureHelperText}</FormHelperText>
    </FormControl>
    </Box>
    <Box className={btnStyles.navbuttons} >
              <Button startIcon={<ArrowBackIosNewIcon />} className={btnStyles.backBtn} size="large" variant="contained" onClick={() => goBack()}>Back</Button>
              <Button endIcon={<ArrowForwardIosIcon />} className={btnStyles.nextBtn} size="large" variant="contained"
                  onClick={() => nextPage()}
              >Next</Button>

          </Box>
          <Box className={btnStyles.bottomNavContainer}>
              <BottomNavigation sx={{
                  width: '100%',
                  position: 'fixed',
                  bottom: 0,
                  height:'70px',
                  zIndex: '1000'
              }} showLabels>
                  <BottomNavigationAction id="navBackButton" label='Back' icon={<ArrowBackIosNewIcon />} onClick={() => goBack()} />
                  <BottomNavigationAction id="navNextButton" label='Next' icon={<ArrowForwardIosIcon />} onClick={() => nextPage()} />
              </BottomNavigation>
          </Box>

    </div>
  );
}

