import { useState, useEffect} from 'react';
import styles from '../styles/body.module.scss'
import {Box, TextField} from '@mui/material';
import useAxios from "../hooks/useAxios";
import axios from "../api/axios";
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useNavigate } from 'react-router-dom';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Button from '@mui/material/Button';
import btnStyles from '../styles/buttons.module.scss'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormHelperText from '@mui/material/FormHelperText';
import{BottomNavigation, BottomNavigationAction} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete';
export default function Involved({reportType, supplierSelection, setSupplier, professionSelection, setProfession, nameOfCompleting, setNameOfCompleting, isSiteTeam, setIsSiteTeam, workingArea, setWorkingArea, checked, setChecked, showTextfield, setShowTextField, supplierTextField, setSupplierTextField, adminFirstName, adminLastName, memberOfThePublic, setMemberOfThePublic}){
    const navigate = useNavigate();
    const goBack = () => navigate(-1);


    const [supplier] = useAxios({
        axiosInstance:axios,
        method:'get',
        url:'/allSuppliers'
    });

    const [profession] = useAxios({
        axiosInstance:axios,
        method:'get',
        url:'/allProfessions'
    });

    const [nameTextField, setNameTextField] = useState(false);

    useEffect(()=>{
        if(reportType == 'Accident/Injury'){
           setNameOfCompleting(adminFirstName+" "+adminLastName);
           setNameTextField(true);
        }
    },[]);

    
    const [error, setError] = useState(false);
    const [supplierError, setSupplierError] = useState(false);
    const [professionError, setProfessionError] = useState(false);
    const [workingAreaError, setWorkingAreaError] = useState(false);
    const [supplierTextFieldError, setSupplierTextFieldError] = useState(false);
    const [nameOfCompletingError, setNameOfCompletingError] = useState(false);

    const [supplierText, setSupplierText] = useState('');
    const [supplierTextFieldErrText, setSupplierTextFieldErrText] = useState('');
    const [professionText, setProfessionText] = useState('');
    const [workingAreaText, setWorkingAreaText] = useState('');
    const [nameOfCompletingText, setNameOfCompletingText] = useState('');
    const [isSiteTeamText, setIsSiteTeamText] = useState('');


    const handleSupplierChange = (event,newValue) => {
        setSupplier(newValue);
    };

    const handleProfessionChange = (event, newValue) => {
        setProfession(newValue);
    };
    const handleNameOfCompletingChange = (newValue) => {
        setNameOfCompleting(newValue);
    };
    const handleWorkingAreaChange = (newValue) => {
        setWorkingArea(newValue);
    };
    const isSiteTeamChange = (e) =>{
        setIsSiteTeam(e.target.value);
    }

    const handleCheckBoxChange = (newValue) => {
        setChecked(newValue.target.checked);
        setShowTextField(newValue.target.checked);
        setSupplierText('');
        setSupplier(null);

    }
    
    const handleSupplierTextFieldChange = (newValue) => {
        setSupplierTextField(newValue);
    };

    

    const nextPage = () => {
        if(checked){
            if(supplierTextField !== "" && workingArea !== "" && isSiteTeam !== null){
                navigate("/who_witnessed")
                    setSupplierText('');
                    setProfessionText('');
                    setWorkingAreaText('');
                    setIsSiteTeamText('');
                    setError(false)
                    setSupplierError(false);
                    setProfessionError(false);
                    setWorkingAreaError(false);
                    setSupplierTextFieldError(false);
            }
            else{
                setSupplierText('');
                setProfessionText('');
                setIsSiteTeamText('');
                setError(false)
                setSupplierError(false);
                setProfessionError(false);
                setWorkingAreaError(false);
                setSupplierTextFieldError(false);
                if(supplierTextField === null || supplierTextField === ""){
                    setSupplierTextFieldErrText('Please provide a company name');
                    setSupplierTextFieldError(true);
                }
                if(workingArea === null || workingArea === ""){
                    setWorkingAreaText('Please provide a working area');
                    setWorkingAreaError(true);
                }
                 if(isSiteTeam === null){
                    setIsSiteTeamText('Please select an option');
                    setError(true);
                }
                
            }
        }
        else if(memberOfThePublic){
            if(workingArea !== "" && isSiteTeam !== null){
                navigate("/who_witnessed")
                    setSupplierText('');
                    setWorkingAreaText('');
                    setIsSiteTeamText('');
                    setError(false)
                    setSupplierError(false);
                    setWorkingAreaError(false);
                    setSupplierTextFieldError(false);
            }
            else if(workingArea === null || workingArea === ""){
                setSupplierText('');
                setProfessionText('');
                setIsSiteTeamText('');
                setError(false)
                setSupplierError(false);
                setWorkingAreaError(false);
                setSupplierTextFieldError(false);

                if(workingArea === null || workingArea === ""){
                    setWorkingAreaText('Please provide a working area');
                    setWorkingAreaError(true);
                }
                
            }
            else if(isSiteTeam === null){
                setSupplierText('');
                setProfessionText('');
                setIsSiteTeamText('');
                setError(false)
                setSupplierError(false);
                setWorkingAreaError(false);
                setSupplierTextFieldError(false);
                setIsSiteTeamText('Please select an option');
                setError(true);
            }
        }
        else{
             setSupplierText('');
                setProfessionText('');
                setIsSiteTeamText('');
                setError(false)
                setSupplierError(false);
                setWorkingAreaError(false);
                setSupplierTextFieldError(false);
            if(supplierSelection !== null  && workingArea !== "" && isSiteTeam !== null){
                setSupplierText('');
                setProfessionText('');
                setWorkingAreaText('');
                setIsSiteTeamText('');
                setError(false)
                setSupplierError(false);
                setWorkingAreaError(false);
                setSupplierTextFieldError(false);

                if(reportType === 'Accident/Injury'){
                    navigate("/injury_details");
                }
                else{
                    navigate("/who_witnessed");
                }      
            }
            else{
                setSupplierText('');
                setProfessionText('');
                setIsSiteTeamText('');
                setError(false)
                setSupplierError(false);
                setProfessionError(false);
                setWorkingAreaError(false);
                setSupplierTextFieldError(false);
                if(supplierSelection === null || supplierSelection === ""){
                    setSupplierText('Please select a company');
                    setSupplierError(true);
                }

                if(workingArea === null || workingArea === ""){
                    setWorkingAreaText('Please provide a working area');
                    setWorkingAreaError(true);
                }
                if(isSiteTeam === null){
                    setIsSiteTeamText('Please select an option');
                    setError(true);
                }
                
            }

        }

        

    }

  return (
      <>
          <Box className={styles.questionH1Top} >
              <h1>Which company was involved?</h1>
              </Box>
              <Box className={styles.textField}>
              <FormControl fullWidth margin="normal" className={styles.field} error={supplierError} disabled={checked || memberOfThePublic}>
                  <Autocomplete
                    disablePortal
                    disableClearable
                    value={supplierSelection}
                    onChange={handleSupplierChange}
                    options={supplier}
                    getOptionLabel={(supplier) => supplier.supplierName}
                    renderInput={(params) => (
                        <TextField {...params}  label="Company" error={supplierError}/>
                      )}
                    disabled={checked || memberOfThePublic}
                    />
                  <FormHelperText>{supplierText}</FormHelperText>
              </FormControl>


              <FormControl fullWidth margin="normal" className={styles.field} error={supplierTextFieldError}>
                 <FormControlLabel control={<Checkbox checked={checked} onChange={handleCheckBoxChange} disabled={memberOfThePublic}/>} label="The company is not in this list." />
                 {
                    showTextfield?<><TextField error={supplierTextFieldError} value={supplierTextField} fullWidth id="outlined-basic" label="Company Name" variant="outlined" onChange={e => handleSupplierTextFieldChange(e.target.value)}  /> <FormHelperText>{supplierTextFieldErrText}</FormHelperText></>:null
                 }
                
              </FormControl>
              </Box>



              <Box className={styles.questionH1}>
                <h1>What is your name?</h1>
                </Box>
                <Box className={styles.textField}>
                <FormControl fullWidth margin="normal" className={styles.field} error={nameOfCompletingError}>
                <TextField  error={nameOfCompletingError} value={nameOfCompleting} disabled={nameTextField} fullWidth id="outlined-basic" label="Name (Optional)" variant="outlined" onChange={e => handleNameOfCompletingChange(e.target.value)}  />
                <FormHelperText>{nameOfCompletingText}</FormHelperText>
                </FormControl>
                </Box>

                <Box className={styles.questionH1Top} >
                    <h1>Are you a member of the site team?</h1>
                    </Box>
                    <Box className={styles.radioGroup}>
                    <FormControl sx={{ m: 3 }} error={error} variant="standard">
                <RadioGroup
                    row
                    aria-labelledby="demo-error-radios"
                    name="row-radio-buttons-group"
                    value={isSiteTeam}
                    onChange={isSiteTeamChange}
                >
                    <FormControlLabel sx={{'& .MuiFormControlLabel-label': {fontSize: '1.3rem'}}}  value={true} control={<Radio sx={{'& .MuiSvgIcon-root': {fontSize: 30}}} />} label="Yes" />
                    <FormControlLabel sx={{'& .MuiFormControlLabel-label': {fontSize: '1.3rem'}}}value={false} control={<Radio sx={{'& .MuiSvgIcon-root': {fontSize: 30}}}/>} label="No" />

                </RadioGroup>
                <FormHelperText>{isSiteTeamText}</FormHelperText>
                </FormControl>
                </Box>

              <Box className={styles.questionH1}>

              <h1>Occupation of the person involved</h1>
              </Box>
              <Box className={styles.textField}>
              <FormControl fullWidth margin="normal" className={styles.field} error={professionError}>
                  <Autocomplete
                    disablePortal
                    disableClearable
                    value={professionSelection}
                    onChange={handleProfessionChange}
                    options={profession}
                    getOptionLabel={(profession) => profession.professionName}
                    renderInput={(params) => (
                        <TextField {...params}  label="Occupation (Optional)" error={professionError}/>
                      )}
                    />
                  <FormHelperText>{professionText}</FormHelperText>
              </FormControl>
              </Box>
              <Box className={styles.questionH1}>
              <h1>Which working area?</h1>
              </Box>
              <Box className={styles.textField}>
              <FormControl fullWidth margin="normal" className={styles.field} error={workingAreaError}>
                 <TextField error={workingAreaError} value={workingArea}  fullWidth id="outlined-basic" label="Working Area" variant="outlined" onChange={e => handleWorkingAreaChange(e.target.value)}  />
                 <FormHelperText>{workingAreaText}</FormHelperText>
              </FormControl>
              </Box>

              
              <Box className={btnStyles.navbuttons} >
              <Button startIcon={<ArrowBackIosNewIcon />} className={btnStyles.backBtn} size="large" variant="contained" onClick={() => goBack()}>Back</Button>
              <Button endIcon={<ArrowForwardIosIcon />} className={btnStyles.nextBtn} size="large" variant="contained"
                  onClick={() => nextPage()}
              >Next</Button>

          </Box>
          <Box className={btnStyles.bottomNavContainer}>
              <BottomNavigation sx={{
                  width: '100%',
                  position: 'fixed',
                  bottom: 0,
                  height:'70px',
                  zIndex: '1000'
              }} showLabels>
                  <BottomNavigationAction id="navBackButton" label='Back' icon={<ArrowBackIosNewIcon />} onClick={() => goBack()} />
                  <BottomNavigationAction id="navNextButton" label='Next' icon={<ArrowForwardIosIcon />} onClick={() => nextPage()} />
              </BottomNavigation>
          </Box>

      </>
      
  )
}
