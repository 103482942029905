import { useState, useLayoutEffect, useEffect } from 'react';
import styles from '../styles/body.module.scss'
import {Box, TextField} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from '../api/axios';
import useAxiosFunction from '../hooks/useAxiosFunction';
import LogoutIcon from '@mui/icons-material/Logout';
import UploadIcon from '@mui/icons-material/Upload';
import Button from '@mui/material/Button';
import btnStyles from '../styles/buttons.module.scss'
import{BottomNavigation, BottomNavigationAction} from '@mui/material'
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Autocomplete from '@mui/material/Autocomplete';
import {CopyField} from '@eisberg-labs/mui-copy-field';

export default function SendReportReview({reportReviewLink, siteSelection, adminID, reportID, setReportReviewLink, setSite, setAdminID, setReportID}){
    const navigate = useNavigate();
    
    function goBack(){
        setAdminID('');
        navigate(-1);
    }

    const [response, error, loading, axiosFetch] = useAxiosFunction();
    const [emailResult, setEmailResult] = useState(false);
    const [usersError, setUsersError] = useState(false);
    const [usersText, setUsersText] = useState('');
    const [result, setResult] = useState(false);
    const [users, setUsers] = useState(false);
    const[selectedUsers, setSelectedUsers] = useState(null);

    useLayoutEffect(() => {
        populateSubcontractors();
      }, [adminID, siteSelection.id]);

      useLayoutEffect(()=>{
        populateAction();
    },[result])

    
    useEffect(()=>{
        if(adminID == 0 || adminID == '' || adminID == null){
            navigate("/login");
        }
    },[]);

    useLayoutEffect(()=>{
        sentEmailsAction();
    },[emailResult])
      

      const populateSubcontractors = async e => {

        const bodyObj = JSON.stringify({
            "id":adminID,
            "site_id":siteSelection.id

        });
          try {
              await axiosFetch({
                  axiosInstance: axios,
                  method: 'post',
                  url: '/getUsersFromSite',
                  requestConfig: {
                      headers: {
                          'Content-Type': 'application/json',
                      },
                      data: bodyObj
                  }

              })
          } catch (err) {
              console.log(err);

          }
          setResult(true);
      }


      function populateAction(){
        setUsers(response);
    }

    const handleUsersChange = (event, newValue) => {
        setSelectedUsers(newValue);
    };

    const nextPage =  async e => {
        if(selectedUsers === null){
            setUsersText('Please select at least one individual');
            setUsersError(true);
        }
        else{
            //submit and send data
            setUsersText('');
            setUsersError(false);

            const bodyObj = JSON.stringify({
                "adminID":adminID,
                "reportID":reportID,
                "reportReviewLink":reportReviewLink,
                "reportUsers":selectedUsers

            });

            try {
                await axiosFetch({
                  axiosInstance: axios,
                  method: 'post',
                  url: '/sendSubcontractorEmails',
                  requestConfig: {
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    data: bodyObj
                  }
      
                })
              } catch (err) {
                console.log(err);
      
              }

              setEmailResult(true);

        }




    }

    function sentEmailsAction(){
        if(response.status === 200){
            setReportReviewLink('');
            setSite('');
            setAdminID('');
            setReportID('');
            navigate('/success_email');
               
    
       }
       else if(response.status === 404){
        setUsersText('An issue has occurred when trying to send emails to the subcontractor you have selected. Please try again, if this error persists please contact the helpdesk : admin@magrockinduction.co.uk');
        setUsersError(true)

       }
    }

  return (
      <>
      <Box className={styles.questionH1Top } >
      <h1>Select the individuals to email the link to this report</h1>
      </Box>
      <Box className={styles.header} >
        <FormControl fullWidth margin="normal" className={styles.field}  >
        <FormHelperText id="photoText">This report can be sent to multiple people. Once you have selected the first individual below, you can select more individuals to email.</FormHelperText>
        </FormControl>
        </Box>
      
              <Box className={styles.questionH1}>
              <h1 id="emailLoginStyle">Individuals to email</h1>
              </Box>
              <Box className={styles.textField} >
              <FormControl fullWidth margin="normal" className={styles.field} error={usersError}>
                  <Autocomplete
                    multiple
                    id="fixed-tags-demo"
                      disablePortal
                      disableClearable
                      onChange={handleUsersChange}
                      options={users}
                      getOptionLabel={(user) => user.first_name+" "+user.last_name+" ("+user.company_name+")"}
                      renderInput={(params) => (
                          <TextField {...params} label="Name (Supplier)" error={usersError} />
                      )}
                  />
                  <FormHelperText>{usersText}</FormHelperText>
              </FormControl>
          </Box>
          <Box  className={styles.successHeader}>
                <CopyField className={styles.field}
                label="Link to review this report and action"
                value={reportReviewLink}
                copyTooltip={"Copy link"}
              />
          </Box>
              <Box className={btnStyles.navbuttons} >
              <Button startIcon={<LogoutIcon />} className={btnStyles.backBtn} size="large" variant="contained" onClick={() => goBack()}>Log Out</Button>
              <Button endIcon={<UploadIcon />} className={btnStyles.nextBtn} size="large" variant="contained"
                  onClick={() => nextPage()}
              >Send Emails</Button>

          </Box>
          <Box className={btnStyles.bottomNavContainer}>
              <BottomNavigation sx={{
                  width: '100%',
                  position: 'fixed',
                  bottom: 0,
                  height:'70px',
                  zIndex: '1000'
              }} showLabels>
                  <BottomNavigationAction id="navBackButton" label='Back' icon={<LogoutIcon />} onClick={() => goBack()} />
                  <BottomNavigationAction id="navNextButton" label='Next' icon={<UploadIcon />} onClick={() => nextPage()} />
              </BottomNavigation>
          </Box>
      </>
      
  )
}
