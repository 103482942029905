import { useState, useLayoutEffect } from 'react';
import styles from '../styles/body.module.scss'
import btnStyles from '../styles/buttons.module.scss'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import { useNavigate } from 'react-router-dom';
import{BottomNavigation, BottomNavigationAction} from '@mui/material'

export default function SuccessEmail({reportSender, reportReviewLink, setReportReviewLink , setSite}) {
  const navigate = useNavigate();

 
  const homePage = () => {
    setReportReviewLink('');
    setSite(null);
    if(reportSender === 'operative'){
      navigate('/');
    }
    else if(reportSender === 'memberOfPublic'){
      navigate('/public');
    }
    
  }


  return (

    <>
      <Box className={styles.successHeader} >
        <h1>Thank you for submitting your report</h1>
        </Box>
        <Box className={styles.header} >
        <div className={styles.successIcon}> 
        {<CheckCircleOutlineIcon fontSize="inherit"/>}
        </div>
        <h4>Emails have been sent to the selected subcontractors.</h4>
        </Box>

        <Box className={btnStyles.singleButtonContainer}>
        <Button startIcon={<ContentPasteIcon />} className={btnStyles.nextBtn} size="large" variant="contained"
          onClick={() => homePage()} >Make another report</Button>
      </Box>
      <Box className={btnStyles.bottomNavContainer}>
            <BottomNavigation sx={{width:'100%',
             position:'fixed', 
             bottom:0,
             height:'70px',
             zIndex: '1000'
             }} showLabels>
              <BottomNavigationAction  id="navNextButton" label='Make another report' icon={<ContentPasteIcon />} onClick={()=> homePage()}/>
            </BottomNavigation>
      </Box>

    </>
  );
}

