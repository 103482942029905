import { useRef, useState, useEffect, useLayoutEffect, useReducer} from 'react';
import styles from '../styles/body.module.scss'
import {Box, TextField} from '@mui/material';
import axios from '../api/axios';
import useAxiosFunction from '../hooks/useAxiosFunction';
import FormControl from '@mui/material/FormControl';
import { useNavigate } from 'react-router-dom';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import LoginIcon from '@mui/icons-material/Login';
import Button from '@mui/material/Button';
import btnStyles from '../styles/buttons.module.scss'
import FormHelperText from '@mui/material/FormHelperText';
import{BottomNavigation, BottomNavigationAction} from '@mui/material'

export default function Login({setAdminID, reportType, setAdminFirstName, setAdminLastName}){
    const navigate = useNavigate();
    const goBack = () => navigate(-1);
    const emailRef = useRef();
    const passRef = useRef();

    console.log("reportType: "+reportType);

    const [response, error, loading, axiosFetch] = useAxiosFunction();

    const reducer = (state, action) =>{
        switch(action.type){
            case 'email':
                return {...state, email : action.payload};
            case 'password':
                return {...state, password : action.payload};
            case 'emailError':
                return {...state, emailError : action.payload};
                case 'passwordError':
                    return {...state, passwordError : action.payload};
            case 'error':
                return {...state, error : action.payload};
            default:
                 throw new Error();
        }
    }

    const [state, dispatch] = useReducer(reducer,{
        email:"", 
        emailError:"",
        password:"",
        passwordError:"",
        error:false
    });
    const [result, setResult] = useState(false);

    useEffect(() => {
        dispatch({type:'error', payload: false});
        dispatch({type:'emailError', payload: ''});
        dispatch({type:'passwordError', payload: ''});
        emailRef.current.focus();
    }, [])
    
    useLayoutEffect(()=>{
        loginAction();
        return()=>{
            setResult(false);
            dispatch({type:'error', payload: false});
            dispatch({type:'emailError', payload: ''});
            dispatch({type:'passwordError', payload: ''});
        }
    },[result])
    

    const validate = () => {
        let isError = false;
        var email_regex = /^[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,125}[a-zA-Z]{2,63}$/;
        if (state.email == "") {

            const emerr = "Please enter your email address."
            isError = true;
            dispatch({type:'emailError', payload: emerr});
        }
        else if (!email_regex.test(state.email)) {

            const emerr = "Please enter a valid personal email address containing only letters, numbers or any of these special characters: _ % . + - @"
            isError = true;
            dispatch({type:'emailError', payload: emerr});
        }

        if (state.password == "") {

            const emerr = "Please enter your password."
            isError = true;
            dispatch({type:'passwordError', payload: emerr});
        }


        if (isError) {
            dispatch({type:'error', payload: true});
        }
        return isError;
    }

    const nextPage =  async e => {
        const err = validate();
        if(!err){

            const bodyObj = JSON.stringify({
                "email":state.email,
                "passwordHash":state.password

            });

            try {
                await axiosFetch({
                  axiosInstance: axios,
                  method: 'post',
                  url: '/loginCheck',
                  requestConfig: {
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    data: bodyObj
                  }
      
                })
              } catch (err) {
                console.log(err);
      
              }

            setResult(true);

        }
    }
        function loginAction(){
            if(response.status === 200){
                    setAdminID(response.adminId);
                    setAdminFirstName(response.adminFirstName);
                    setAdminLastName(response.adminLastName);
                    if(reportType === "Accident/Injury"){
                        navigate("/site");
                      }else{
                        navigate("/send_report_review");
                      }

                    
                   
        
           }else{
               dispatch({type:'error', payload: true});
               if (!error) {
                dispatch({type:'emailError', payload:'No Server Response'});
               } 
               else if (response.status === 201) {
                dispatch({type:'emailError', payload:'The email address or password is incorrect. Please try again.'});
               }
               else if (response.status === 302) {
                dispatch({type:'emailError', payload:'The email address or password is incorrect. Please try again.'});
               } else if (response.status === 403) {
                dispatch({type:'emailError', payload:'Unauthorised'});
               }
               else if (response.status === 404) {
                dispatch({type:'emailError', payload:'The email address or password is incorrect. Please try again.'});
               }
               else {    
                dispatch({type:'emailError', payload:'Login Failed'})
               }
               emailRef.current.focus();
           }
        }
        

    

  return (
      <>
          <Box className={styles.successHeader} >
              {reportType === "Accident/Injury" ?
                  <h1>Please log in to complete this accident injury report</h1>
                  :
                  <h1>Please log in to select individuals from the site to send this report to</h1>
              }
          </Box>
          <Box className={styles.header} >
              <FormControl fullWidth margin="normal" className={styles.field}  >
                  {reportType === "Accident/Injury" ?
                      <FormHelperText id="infoText">You must be an administrator on The Magrock Induction to log in and complete this report.</FormHelperText>
                      :
                      <FormHelperText id="infoText">You must be an administrator on The Magrock Induction to log in and select individuals.</FormHelperText>
                  }

              </FormControl>
          </Box>
        
      
              <Box className={styles.questionH1}>
              <h1 id="emailLoginStyle">Email</h1>
              </Box>
              <Box className={styles.textField}>
              <FormControl fullWidth margin="normal" className={styles.field} error={state.error}>
                 <TextField fullWidth id="outlined-basic" label="Email"
                  type="text" ref={emailRef} autoComplete="off" error={state.error}
                 variant="outlined" onChange={e => dispatch({type:'email', payload: e.target.value})}  />
                 <FormHelperText>{state.emailError}</FormHelperText>
              </FormControl>
              </Box>

              <Box className={styles.questionH1}>
              <h1>Password</h1>
              </Box>
              <Box className={styles.textField}>
              <FormControl fullWidth margin="normal" className={styles.field} error={state.error}>
                 <TextField fullWidth id="outlined-basic" label="Password"
                  type="password" ref={passRef}    error={state.error}
                 variant="outlined" onChange={(e) => dispatch({type:'password', payload: e.target.value})}  />
                 <FormHelperText>{state.passwordError}</FormHelperText>
              </FormControl>
              </Box>
              <Box className={btnStyles.navbuttons} >
              <Button startIcon={<ArrowBackIosNewIcon />} className={btnStyles.backBtn} size="large" variant="contained" onClick={() => goBack()}>Back</Button>
              <Button endIcon={<LoginIcon />} className={btnStyles.nextBtn} size="large" variant="contained"
                  onClick={() => nextPage()}
              >Log In</Button>

          </Box>
          <Box className={btnStyles.bottomNavContainer}>
              <BottomNavigation sx={{
                  width: '100%',
                  position: 'fixed',
                  bottom: 0,
                  height:'70px',
                  zIndex: '1000'
              }} showLabels>
                  <BottomNavigationAction id="navBackButton" label='Back' icon={<ArrowBackIosNewIcon />} onClick={() => goBack()} />
                  <BottomNavigationAction id="navNextButton" label='Next' icon={<LoginIcon />} onClick={() => nextPage()} />
              </BottomNavigation>
          </Box>
      </>
      
  )
}
