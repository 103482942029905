import { useState, useLayoutEffect, useEffect } from 'react';
import styles from '../styles/body.module.scss'
import {Box, TextField} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { useNavigate } from 'react-router-dom';
import axios from '../api/axios';
import useAxiosFunction from '../hooks/useAxiosFunction';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Button from '@mui/material/Button';
import btnStyles from '../styles/buttons.module.scss'
import FormHelperText from '@mui/material/FormHelperText';
import{BottomNavigation, BottomNavigationAction} from '@mui/material'

export default function AccidentUserDetails({namesOfPplInvolved, setNamesOfPplInvolved, address1, setAddress1, address2, setAddress2,
city, setCity, county, setCounty, postcode, setPostcode, consentToShare, setConsentToShare,
siteSelection, adminID,
notOnSite, setNotOnSite, showNameTextField, setShowNametextField, injuredIndividual, setInjuredIndividual}){
    const navigate = useNavigate();
    const goBack = () => navigate(-1);

    useEffect(()=>{
        if(adminID == 0 || adminID == '' || adminID == null){
            navigate("/login");
        }
    },[]);

    const [response, error, loading, axiosFetch] = useAxiosFunction();
    const [result, setResult] = useState(false);
    const [users, setUsers] = useState(false);

    const [namesOfPplInvolvedError, setNamesOfPplInvolvedError] = useState(false);
    const [namesOfPplInvolvedText, setNamesOfPplInvolvedText] = useState('');

    const [namesOfPplInvolvedDropdownError, setNamesOfPplInvolvedDropdownError] = useState(false);
    const [namesOfPplInvolvedDropdownText, setNamesOfPplInvolvedDropdownText] = useState('');

    const [address1Error, setAddress1Error] = useState(false);
    const [address1Text, setAddress1Text] = useState('');

    const [address2Error, setAddress2Error] = useState(false);
    const [address2Text, setAddress2Text] = useState('');
    
    const [cityError, setCityError] = useState(false);
    const [cityText, setCityText] = useState('');

    const [countyError, setCountyError] = useState(false);
    const [countyText, setCountyText] = useState('');

    const [postcodeError, setPostcodeError] = useState(false);
    const [postcodeText, setPostcodeText] = useState('');


    useLayoutEffect(() => {
        populateSubcontractors();
      }, [adminID, siteSelection.id]);

      useLayoutEffect(()=>{
        populateAction();
    },[result])

    const populateSubcontractors = async e => {

        const bodyObj = JSON.stringify({
            "id":adminID,
            "site_id":siteSelection.id

        });
          try {
              await axiosFetch({
                  axiosInstance: axios,
                  method: 'post',
                  url: '/getAllUsersFromSite',
                  requestConfig: {
                      headers: {
                          'Content-Type': 'application/json',
                      },
                      data: bodyObj
                  }

              })
          } catch (err) {
              console.log(err);

          }
          setResult(true);
      }


      function populateAction(){
        setUsers(response);
    }

    const handleNamesOfPplInvolvedDropdownChange = (event, newValue) => {
        setInjuredIndividual(newValue);
    };
    
    const handleNamesOfPplInvolvedChange = (newValue) => {
        setNamesOfPplInvolved(newValue);
    };

    const handleAddress1Change = (newValue) => {
        setAddress1(newValue);
    }

    const handleAddress2Change = (newValue) => {
        setAddress2(newValue);
    }

    const handleCityChange = (newValue) => {
        setCity(newValue);
    }

    const handleCountyChange = (newValue) => {
        setCounty(newValue);
    }

    const handlePostcodeChange = (newValue) => {
        setPostcode(newValue);
    }

    const handleCheckBoxChange = (newValue) => {
        setConsentToShare(newValue.target.checked);

    }

    const handleCheckBoxNameChange = (newValue) => {
        setNotOnSite(newValue.target.checked);
        setShowNametextField(newValue.target.checked);
        setInjuredIndividual(null);

    }

    const nextPage = () => {
        var pc_regex = /^[a-zA-Z]{1,2}[0-9][0-9a-zA-Z]?\s?[0-9][a-zA-Z]{2}$/;

        if(notOnSite){
            if(namesOfPplInvolved !== "" &&
            address1 !== "" &&
            city !== "" &&
            county !== "" &&
            (postcode !== "" && pc_regex.test(postcode))){

            setNamesOfPplInvolvedDropdownText('');
            setNamesOfPplInvolvedDropdownError(false);

            setNamesOfPplInvolvedText('');
            setNamesOfPplInvolvedError(false);

            setAddress1Text('');
            setAddress1Error(false);

            setAddress2Text('');
            setAddress2Error(false);

            setCityText('');
            setCityError(false);

            setCountyText('');
            setCountyError(false);

            setPostcodeText('');
            setPostcodeError(false);

            navigate("/accident_summary");

        }
        else{

            setNamesOfPplInvolvedDropdownText('');
            setNamesOfPplInvolvedDropdownError(false);

            setNamesOfPplInvolvedText('');
            setNamesOfPplInvolvedError(false);

            setAddress1Text('');
            setAddress1Error(false);

            setAddress2Text('');
            setAddress2Error(false);

            setCityText('');
            setCityError(false);

            setCountyText('');
            setCountyError(false);

            setPostcodeText('');
            setPostcodeError(false);
            if(namesOfPplInvolved === ""){
                setNamesOfPplInvolvedText('Please provide the name of the person invovled');
                setNamesOfPplInvolvedError(true);
            }

            if(address1 === ""){
                setAddress1Text('Please enter an address.');
                setAddress1Error(true);
            }
            if(address1.length > 50){
                setAddress1Text('Address fields must be less than 50 characters.');
                setAddress1Error(true);
            }
            if(address2.length > 50){
                setAddress2Text('Address fields must be less than 50 characters.');
                setAddress2Error(true);
            }
            if(city === ""){
                setCityText('Please enter a city.');
                setCityError(true);
            }
            if(city.length > 50){
                setCityText('Address fields must be less than 50 characters.');
                setCityError(true);
            }
            if(county === ""){
                setCountyText('Please enter a county.');
                setCountyError(true);
            }
            if(county.length > 50){
                setCountyText('Address fields must be less than 50 characters.');
                setCountyError(true);
            }
            if(postcode === "" || !pc_regex.test(postcode)){
                setPostcodeText('Please enter a valid postcode.');
                setPostcodeError(true);
            }
            
        }
        }
        else{
            console.log("notOnSite: "+notOnSite);
            console.log("injuredIndividual: "+injuredIndividual)
            //user selected an individual
            if(injuredIndividual !== null &&
            address1 !== "" &&
            city !== "" &&
            county !== "" &&
            (postcode !== "" && pc_regex.test(postcode))){

            setNamesOfPplInvolvedDropdownText('');
            setNamesOfPplInvolvedDropdownError(false);
            

            setNamesOfPplInvolvedText('');
            setNamesOfPplInvolvedError(false);

            setAddress1Text('');
            setAddress1Error(false);

            setAddress2Text('');
            setAddress2Error(false);

            setCityText('');
            setCityError(false);

            setCountyText('');
            setCountyError(false);

            setPostcodeText('');
            setPostcodeError(false);

            navigate("/accident_summary");

        }
        else{

            setNamesOfPplInvolvedDropdownText('');
            setNamesOfPplInvolvedDropdownError(false);

            setNamesOfPplInvolvedText('');
            setNamesOfPplInvolvedError(false);

            setAddress1Text('');
            setAddress1Error(false);

            setAddress2Text('');
            setAddress2Error(false);

            setCityText('');
            setCityError(false);

            setCountyText('');
            setCountyError(false);

            setPostcodeText('');
            setPostcodeError(false);
            if(injuredIndividual === null || injuredIndividual === ""){
                setNamesOfPplInvolvedDropdownText('Please select the name of the person invovled');
                setNamesOfPplInvolvedDropdownError(true);
            }

            if(address1 === ""){
                setAddress1Text('Please enter an address.');
                setAddress1Error(true);
            }
            if(address1.length > 50){
                setAddress1Text('Address fields must be less than 50 characters.');
                setAddress1Error(true);
            }
            if(address2.length > 50){
                setAddress2Text('Address fields must be less than 50 characters.');
                setAddress2Error(true);
            }
            if(city === ""){
                setCityText('Please enter a city.');
                setCityError(true);
            }
            if(city.length > 50){
                setCityText('Address fields must be less than 50 characters.');
                setCityError(true);
            }
            if(county === ""){
                setCountyText('Please enter a county.');
                setCountyError(true);
            }
            if(county.length > 50){
                setCountyText('Address fields must be less than 50 characters.');
                setCountyError(true);
            }
            
            if(postcode === "" || !pc_regex.test(postcode)){
                setPostcodeText('Please enter a valid postcode.');
                setPostcodeError(true);
            }
            
        }
        }

        

    }

  return (
      <>
        <Box className={styles.questionH1Top} >
              <h1>Who was involved?</h1>
              </Box>
              <Box className={styles.textField}>
              <FormControl fullWidth margin="normal" className={styles.field} error={namesOfPplInvolvedDropdownError} disabled={notOnSite}>
                  <Autocomplete
                    disablePortal
                    disableClearable
                    value={injuredIndividual}
                    onChange={handleNamesOfPplInvolvedDropdownChange}
                    options={users}
                      getOptionLabel={(user) => user.first_name+" "+user.last_name+" ("+user.company_name+")"}
                      renderInput={(params) => (
                          <TextField {...params} label="Person involved (Supplier)" error={namesOfPplInvolvedDropdownError} />
                      )}
                    disabled={notOnSite}
                    />
                  <FormHelperText>{namesOfPplInvolvedDropdownText}</FormHelperText>
              </FormControl>


              <FormControl fullWidth margin="normal" className={styles.field} error={namesOfPplInvolvedError}>
                 <FormControlLabel control={<Checkbox checked={notOnSite} onChange={handleCheckBoxNameChange} />} label="The person involved is not in this list." />
                 {
                    showNameTextField?<><TextField  error={namesOfPplInvolvedError} value={namesOfPplInvolved} fullWidth id="outlined-basic" label="Name of person involved" variant="outlined" onChange={e => handleNamesOfPplInvolvedChange(e.target.value)}  /><FormHelperText>{namesOfPplInvolvedText}</FormHelperText></>:null
                 }
                
              </FormControl>
              </Box>

          <Box className={styles.questionH1} >
              <h1>Address Line 1</h1>
              </Box>
              <Box className={styles.textField}>
              <FormControl fullWidth margin="normal" className={styles.field} error={address1Error}>
                 <TextField  error={address1Error} value={address1} fullWidth id="outlined-basic" label="Address line 1" variant="outlined" onChange={e => handleAddress1Change(e.target.value)}  />
                 <FormHelperText>{address1Text}</FormHelperText>
              </FormControl>

          </Box>

          <Box className={styles.questionH1} >
              <h1>Address Line 2</h1>
              </Box>
              <Box className={styles.textField}>
              <FormControl fullWidth margin="normal" className={styles.field} error={address2Error}>
                 <TextField  error={address2Error} value={address2} fullWidth id="outlined-basic" label="Address line 2" variant="outlined" onChange={e => handleAddress2Change(e.target.value)}  />
                 <FormHelperText>{address2Text}</FormHelperText>
              </FormControl>

          </Box>

          <Box className={styles.questionH1} >
              <h1>City</h1>
              </Box>
              <Box className={styles.textField}>
              <FormControl fullWidth margin="normal" className={styles.field} error={cityError}>
                 <TextField  error={cityError} value={city} fullWidth id="outlined-basic" label="City" variant="outlined" onChange={e => handleCityChange(e.target.value)}  />
                 <FormHelperText>{cityText}</FormHelperText>
              </FormControl>

          </Box>

          <Box className={styles.questionH1} >
              <h1>County</h1>
              </Box>
              <Box className={styles.textField}>
              <FormControl fullWidth margin="normal" className={styles.field} error={countyError}>
                 <TextField  error={countyError} value={county} fullWidth id="outlined-basic" label="County" variant="outlined" onChange={e => handleCountyChange(e.target.value)}  />
                 <FormHelperText>{countyText}</FormHelperText>
              </FormControl>

          </Box>
          <Box className={styles.questionH1} >
              <h1>Postcode</h1>
              </Box>
              <Box className={styles.textField}>
              <FormControl fullWidth margin="normal" className={styles.field} error={postcodeError}>
                 <TextField  error={postcodeError} value={postcode} fullWidth id="outlined-basic" label="Postcode" variant="outlined" onChange={e => handlePostcodeChange(e.target.value)}  />
                 <FormHelperText>{postcodeText}</FormHelperText>
              </FormControl>

          </Box>
          <Box className={styles.questionH1} >
              <h1>Does the person involved consent to their personal information being shared with 3rd party health and safety representatives?</h1>
              </Box>
              <Box className={styles.textField}>
                <FormControl fullWidth margin="normal" className={styles.field} >
                        <FormControlLabel control={<Checkbox checked={consentToShare} onChange={handleCheckBoxChange} />} label="The person involved consents." />
                        
                    </FormControl>
                </Box>
            <Box className={btnStyles.navbuttons} >
              <Button startIcon={<ArrowBackIosNewIcon />} className={btnStyles.backBtn} size="large" variant="contained" onClick={() => goBack()}>Back</Button>
              <Button endIcon={<ArrowForwardIosIcon />} className={btnStyles.nextBtn} size="large" variant="contained"
                  onClick={() => nextPage()}
              >Next</Button>

          </Box>
          <Box className={btnStyles.bottomNavContainer}>
              <BottomNavigation sx={{
                  width: '100%',
                  position: 'fixed',
                  bottom: 0,
                  height:'70px',
                  zIndex: '1000'
              }} showLabels>
                  <BottomNavigationAction id="navBackButton" label='Back' icon={<ArrowBackIosNewIcon />} onClick={() => goBack()} />
                  <BottomNavigationAction id="navNextButton" label='Next' icon={<ArrowForwardIosIcon />} onClick={() => nextPage()} />
              </BottomNavigation>
          </Box>

      </>
      
  )
}
