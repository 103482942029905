import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
const useAxiosFunction = () =>{
    const [response, setResponse] = useState([]);
    const [error, setError] = useState([]);
    const [loading, setLoading] = useState(false);
    const [controller, setController] = useState();


    const navigate = useNavigate();

    const axiosFetch = async (configObj)=>{
        const{
            axiosInstance,
            method,
            url,
            requestConfig = {}
        }= configObj;

        try{
            setLoading(true);
            const ctrl = new AbortController();
            setController(ctrl);
            const res = await axiosInstance({
                url, 
                method: method.toLowerCase(),
                ...requestConfig,
                signal: ctrl.signal,
                });
            setResponse(res.data);
            return res.data;
        }catch(err){
            setError(err.response.status);
            navigate('/');

        }finally{
            setLoading(false);
        }
    }

    useEffect(()=>{
        //useEffect cleanup function
        return ()=> controller && controller.abort();  
    },[controller])

    return [response,error,loading,axiosFetch]
}

export default useAxiosFunction