import { useState, useEffect, useLayoutEffect } from 'react';
import styles from '../styles/body.module.scss'
import { Box, TextField } from '@mui/material';
import useAxios from "../hooks/useAxios";
import axios from "../api/axios";
import useAxiosFunction from '../hooks/useAxiosFunction';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import FormControl from '@mui/material/FormControl';
import { BottomNavigation, BottomNavigationAction } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import UploadIcon from '@mui/icons-material/Upload';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Button from '@mui/material/Button';
import btnStyles from '../styles/buttons.module.scss'
import FormHelperText from '@mui/material/FormHelperText';
import Autocomplete from '@mui/material/Autocomplete';
import LinearProgressWithLabel from '../components/LinearProgressWithLabel';

import { FilePond, registerPlugin } from 'react-filepond'
import 'filepond/dist/filepond.min.css'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginValidateType from 'filepond-plugin-file-validate-type';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateSize, FilePondPluginValidateType)


export default function Public({ setReportSender, dateError, setDateError, timeDate, setTimeDate, siteSelection, setSite, notes, setNotes, imgFile, setImgFile, file, setFile }) {
    const navigate = useNavigate();
    const [response, error2, loading, axiosFetch] = useAxiosFunction();

    const [result, setResult] = useState(false);
    const [imgResult, setImgResult] = useState(false);

    const [progress, setProgress] = useState(false);
    const [lineProgress, setLineProgress] = useState(0);

    const [siteError, setSiteError] = useState(false);
    const [dateTimeText, setDateTimeText] = useState('');
    const [siteText, setSiteText] = useState('');

    const [notesError, setNotesError] = useState(false);
    const [notesText, setNotesText] = useState('');

    const [imageError, setImageError] = useState(false);
    const [imageText, setImageText] = useState('');

    const handleNotesChange = (newValue) => {
        setNotes(newValue);
    };


    const [site] = useAxios({
        axiosInstance: axios,
        method: 'get',
        url: '/allSites'
    });



    const handleTimeDateChange = (newValue) => {
        setTimeDate(newValue);
    };

    const handleSiteChange = (event, newValue) => {
        setSite(newValue);
    };

    const handlePhotoUploadChange = () => {
        setImageError(false);
        setImageText("");
        if (Array.isArray(file) && file.length) {
            const imageData = new FormData();
            for (var i = 0; i < file.length; i++) {
                imageData.append("files", file[i].file);
                if (file[i].file.size > 10000000) {
                    setImageError(true);
                    setImageText("Images cannot be greater than 10MB");
                    return false;
                }

            }

            setImgFile(imageData);

        }
        return true;
    };

    useEffect(() => {
        if (Array.isArray(file) && file.length) {
            imageUpload();
            setResult(true);
            setLineProgress(50);
        }
        else {
            setResult(true);
            setImgResult(true);
        }
    }, [imgFile]);

    const submit = async e => {
        if (((timeDate !== null && timeDate.toString() !== "" && timeDate.toString() !== "Invalid Date") && !dateError) && siteSelection !== null && notes !== "") {

            setDateTimeText('');
            setSiteText('');
            setDateError(false);
            setSiteError(false);
            setNotesText('');
            setNotesError(false);
            if (notes.length > 250) {
                setNotesText('Observation must be less than 250 characters');
                setNotesError(true);
            }
            else {
                if (handlePhotoUploadChange()) {
                    setLineProgress(25);
                    setReportSender('memberOfPublic');
                    const bodyObj = JSON.stringify({
                        "reportType": "General Public",
                        "observationType": "",
                        "timeDate": timeDate,
                        "siteId": siteSelection ? siteSelection.id : 0,
                        "memberOfThePublic": true,
                        "notes": notes,
                        "sendSupplierReport": false,
                        "riddor": false,
                        "nameOfCompleting": "",
                        "hasImages": Array.isArray(file) && file.length
                    });
                    setLineProgress(35);
                    try {
                        await axiosFetch({
                            axiosInstance: axios,
                            method: 'post',
                            url: '/createObservation',
                            requestConfig: {
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                data: bodyObj
                            }

                        })
                    } catch (err) {
                        console.log(err);

                    }


                }


            }

        }
        else {

            setDateTimeText('');
            setSiteText('');
            setDateError(false);
            setSiteError(false);
            setNotesText('');
            setNotesError(false);

            if (timeDate === null || timeDate.toString() === "Invalid Date" || timeDate.toString() === "") {
                setDateTimeText('Please provide a date and time');
                setDateError(true);
            }
            if (siteSelection === null) {
                setSiteText('Please select a site');
                setSiteError(true);
            }
            if (notes === "") {
                setNotesText('Please provide your observation');
                setNotesError(true);
            }

        }

    }


    useLayoutEffect(() => {
        if (result && imgResult) {
            setResult(false);
            setImgResult(false);
            submitAction();
        }

    }, [result, imgResult])



    const imageUpload = async e => {
        try {
            await axiosFetch({
                axiosInstance: axios,
                method: 'post',
                url: '/imageUpload',
                requestConfig: {
                    headers: {},
                    data: imgFile
                }

            })
            setImgResult(true);
            setLineProgress(75);
        } catch (err) {
            console.log(err);

        }
    }

    function submitAction() {
        if (response === 200) {
            setLineProgress(100);
            setProgress(false);
            clear();
            navigate("/success");
        }
        else {
            console.log(response);
            console.log(error2);

        }
    }
    function clear() {
        setTimeDate(null);
        setSite(null);
        setImgFile();
        setNotes("");
        setFile();
    }
    return (
        <>
            <Box className={styles.questionH1Top} >
                <h1>Describe your observation</h1>
            </Box>
            <Box className={styles.textField} >
                <FormControl fullWidth margin="normal" className={styles.field} error={notesError}>
                    <TextField error={notesError} value={notes} inputProps={{ maxLength: 250 }} helperText={`${notes.length}/${250}`} fullWidth id="outlined-basic" multiline rows={5} label="Observation" variant="outlined" onChange={e => handleNotesChange(e.target.value)} />
                    <FormHelperText>{notesText}</FormHelperText>
                </FormControl>

            </Box>
            <Box className={styles.questionH1} >
                <h1>When did this happen?</h1>
            </Box>
            <Box className={styles.textField} >
                <FormControl fullWidth
                    margin="normal"
                    className={styles.field}
                    error={dateError}
                >
                    <DateTimePicker
                        error={dateError}
                        label="Date and Time"
                        value={timeDate}
                        ampm={false}
                        disableFuture={true}
                        inputFormat="dd/MM/yyyy HH:mm"
                        onChange={handleTimeDateChange}
                        onError={(reason, value) => {
                            if (reason) {
                                setDateTimeText("Please provide a date and time");
                                setDateError(true);
                            } else {
                                setDateTimeText(null);
                                setDateError(false);
                            }
                        }}
                        renderInput={(params) => <TextField {...params} />}
                    />
                    <FormHelperText>{dateTimeText}</FormHelperText>
                </FormControl>
            </Box>
            <Box className={styles.questionH1} >
                <h1>Where did this take place?</h1>
            </Box>
            <Box className={styles.textField} >
                <FormControl fullWidth margin="normal" className={styles.field} error={siteError}>
                    <Autocomplete
                        disablePortal
                        disableClearable
                        value={siteSelection}
                        onChange={handleSiteChange}
                        options={site}
                        getOptionLabel={(site) => site.siteName}
                        renderInput={(params) => (
                            <TextField {...params} label="Sites" error={siteError} />
                        )}
                    />
                    <FormHelperText>{siteText}</FormHelperText>
                </FormControl>
            </Box>


            <Box className={styles.questionH1} >
                <h1>Photo evidence</h1>
            </Box>
            <Box className={styles.textField} >
                <FormControl fullWidth margin="normal" className={styles.field} error={imageError} >
                    <FormHelperText id="photoText">Once you have selected your first photo, tap 'Select or take a photo' again to upload up to 5 photos.</FormHelperText>
                    <FilePond
                        files={file}
                        allowFileTypeValidation={true}
                        acceptedFileTypes={['image/png', 'image/jpeg']}
                        storeAsFile={true}
                        allowMultiple={true}
                        onupdatefiles={setFile}
                        maxFiles={5}
                        labelIdle='<span class="filepond--label-action">Select or take</span>  a photo'
                        allowFileSizeValidation={true}
                        maxFileSize={10000000}
                        labelMaxFileSizeExceeded={'File is too large'}

                    />
                    <FormHelperText>{imageText}</FormHelperText>
                </FormControl>

            </Box>

            <Box className={styles.progressBar}>
                {progress && <LinearProgressWithLabel value={lineProgress} />}

            </Box>

            <Box className={btnStyles.singleButtonContainer}>
                <Button endIcon={<ArrowForwardIosIcon />} className={btnStyles.nextBtn} size="large" variant="contained"
                    onClick={() => submit()}
                >Submit</Button>
            </Box>

            <Box className={btnStyles.bottomNavContainer}>
                <BottomNavigation sx={{
                    width: '100%',
                    position: 'fixed',
                    bottom: 0,
                    height: '70px',
                    zIndex: '1000'
                }} showLabels>
                    <BottomNavigationAction disabled={progress} id="navNextButton" label='Submit' icon={<UploadIcon />} onClick={() => submit()} />
                </BottomNavigation>
            </Box>

        </>

    )
}
