import { useState} from 'react';
import styles from '../styles/body.module.scss'
import {Box, TextField} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { useNavigate } from 'react-router-dom';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Button from '@mui/material/Button';
import btnStyles from '../styles/buttons.module.scss'
import FormHelperText from '@mui/material/FormHelperText';
import{BottomNavigation, BottomNavigationAction} from '@mui/material'

export default function Notes({notes, setNotes}){
    const navigate = useNavigate();
    const goBack = () => navigate(-1);

    const [notesError, setNotesError] = useState(false);
    const [notesText, setNotesText] = useState('');
    
    const handleNotesChange = (newValue) => {
        setNotes(newValue);
    };


    const nextPage = () => {
        if(notes !== ""){
            setNotesText('');
            setNotesError(false);

            if(notes.length >250){
                setNotesText('List of notes must be less than 250 characters');
                setNotesError(true);
            }
            else{

                    navigate("/riddor");
                
            }

        }
        else{

                navigate("/riddor");
            
        }


    }

  return (
      <>
          <Box className={styles.questionH1Top} >
              <h1>Notes or suggestions</h1>
              </Box>
              <Box className={styles.textField} >
              <FormControl fullWidth margin="normal" className={styles.field} error={notesError}>
                 <TextField error={notesError} value={notes} inputProps={{maxLength: 250}} helperText={`${notes.length}/${250}`} fullWidth id="outlined-basic" multiline rows={5} label="Notes (Optional)" variant="outlined" onChange={e => handleNotesChange(e.target.value)}  />
                 <FormHelperText>{notesText}</FormHelperText>
              </FormControl>

              </Box>
              <Box className={btnStyles.navbuttons} >
              <Button startIcon={<ArrowBackIosNewIcon />} className={btnStyles.backBtn} size="large" variant="contained" onClick={() => goBack()}>Back</Button>
              <Button endIcon={<ArrowForwardIosIcon />} className={btnStyles.nextBtn} size="large" variant="contained"
                  onClick={() => nextPage()}
              >Next</Button>

          </Box>
          <Box className={btnStyles.bottomNavContainer}>
              <BottomNavigation sx={{
                  width: '100%',
                  position: 'fixed',
                  bottom: 0,
                  height:'70px',
                  zIndex: '1000'
              }} showLabels>
                  <BottomNavigationAction id="navBackButton" label='Back' icon={<ArrowBackIosNewIcon />} onClick={() => goBack()} />
                  <BottomNavigationAction id="navNextButton" label='Next' icon={<ArrowForwardIosIcon />} onClick={() => nextPage()} />
              </BottomNavigation>
          </Box>
      </>
  )
}
